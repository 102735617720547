import resorts from '~/js/sections/root/includes/resort-slides';
const resortSlides = [];
const resortSliderWrap = document.querySelector('#resortSlider .slide-wrap');
const ratesJSON = beaches_app.page.ratesArray;
let counter = 0;
const $sliderTop = $('#resortSlider .slide-wrap');
const featuredResort = document.getElementById('featuredResort');

const slickSlider = () => {

  const $statusSliderTop = $('#resortsNav .js-slick-slider-top-counter');

  $sliderTop.on('init reInit afterChange', (event, slick, currentSlide) => {
    const i = (currentSlide ? currentSlide : 0) + 1;

    $statusSliderTop.text(`${i}/${slick.slideCount}`);
  });

  $sliderTop.slick({
    centerMode: true,
    centerPadding: '0',
    cssEase: 'linear',
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    prevArrow: $('#resortsNav .js-prev-slide-in-slider-top'),
    nextArrow: $('#resortsNav .js-next-slide-in-slider-top')
  });

  $sliderTop.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    // const slidesLength = slick.$slides.length;

    // if (nextSlide <= slidesLength - 2) {
    //   const nextSlide1 = $sliderTop.find(`div[data-slick-index='${nextSlide}']`).find('.slider-item-inner')[0];
    //   const nextNextSlide = $sliderTop.find(`div[data-slick-index='${nextSlide + 1}']`).find('.slider-item-inner')[0];

    //   const nextSlide1Image = $(nextSlide1).data('bg');
    //   const nextNextSlideImage = $(nextNextSlide).data('bg');

    //   nextSlide1.style.backgroundImage =  `url(${nextSlide1Image})`;
    //   nextNextSlide.style.backgroundImage = `url(${nextNextSlideImage})`;
    // }
    featuredResort.setAttribute('href', resorts.resort[nextSlide].link);
  });
};

resorts.resort.forEach((resort, index, array) => {
  if (index !== 0) {
    const rstCode = resort.rstCode;

    resortSlides.push(`
            <div class='slider-item'>
                <div class='slider-item-inner'>
                  <div class="image progressive">
										<img src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' data-src='${resort.img}' class='lazyload' alt=${resort.name} />
									</div>
                    <div class='resort-title xs-12 lg-10 lg-offset-1 xl-8 xl-offset-2'>
                        
                        <h2>Beaches ${resort.name}</h2>
                        <h3>${resort.city}, ${resort.country}</h3>
                        ${rstCode==='BTC' ? `<img src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' data-src='//cdn.sandals.com/beaches/v12/images/home/resorts/reopen.png' class='lazyload' alt='reopening' />` : ''}

                    </div>
                    <div class='floating-box xs-12 lg-10 lg-offset-1 xl-8 xl-offset-2'>
                        <p>${resort.caption}</p>
                    </div>
                </div>
            </div>
        `);
  }
  counter++;
  if (counter === array.length) {
    $(resortSliderWrap).append(resortSlides.join(''));
    slickSlider();
  }
});
