import newFeatures from '~/js/sections/root/includes/new-at-beaches-slides';
const featuresArray = [];
let counter = 0;
const $sliderTop = $('#newAtSandalsSlider .slide-wrap');

function preloadSlide($slider, currentSlide, amount) {
  const $imagePrevObj = $slider.find(`div[data-slick-index="${currentSlide - amount}"]`).find('.slide-image');
  const $imageNextObj = $slider.find(`div[data-slick-index="${currentSlide + amount}"]`).find('.slide-image');
  const imagePrevSrc = $imagePrevObj.data('src');
  const imageNextSrc = $imageNextObj.data('src');

  if (imagePrevSrc !== $imagePrevObj.attr('src')) {
    $imagePrevObj.attr('src', imagePrevSrc);
  }
  if (imageNextSrc !== $imageNextObj.attr('src')) {
    $imageNextObj.attr('src', imageNextSrc);
  }
}

const slickSlider = () => {

  const $statusSliderTop = $('#newAtSandalsSlider .js-slick-slider-top-counter');

  $sliderTop.on('init reInit afterChange', (event, slick, currentSlide) => {
    const i = (currentSlide ? currentSlide : 0) + 1;

    $statusSliderTop.text(`${i}/${slick.slideCount}`);
  });

  $sliderTop.slick({
    centerMode: true,
    cssEase: 'ease',
    fade: false,
    infinite: true,
    pauseOnFocus: true,
    pauseOnHover: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 6000,
    variableWidth: true,
    slidesToScroll: 1,
    prevArrow: $('#newAtSandalsSlider .js-prev-slide-in-slider-top'),
    nextArrow: $('#newAtSandalsSlider .js-next-slide-in-slider-top'),
    responsive: [{
      breakpoint: 767,
      settings: {
        centerMode: false
      }
    }]
  });

  $sliderTop.on('beforeChange', (event, slick, currentSlide) => {
    preloadSlide($sliderTop, currentSlide, 2);
    preloadSlide($sliderTop, currentSlide, 3);
  });

  $sliderTop.on('afterChange', (event, slick, currentSlide) => {
    preloadSlide($sliderTop, currentSlide, 1);
    preloadSlide($sliderTop, currentSlide, 2);
  });
};

newFeatures.feature.forEach((feature, index, array) => {
  const cond = (feature.link !== '');
  const tag = (feature.tag !== '');

  featuresArray.push(`
        <div class='slider-item'>
            <div class='slider-item-inner'>
                <img src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' data-src='${feature.img}' alt='${feature.alt}' class='slide-image'>
            </div>
            <div class='text-box'>
                ${tag ? `<div class='included'>${feature.tag}</div>` : ''}
                <h4>${feature.subHeader}</h4>
                <h3>${feature.header}</h3>
                <p>${feature.paragraph}</p>
                ${cond ? `${feature.link}` : ''}
            </div>
        </div>
        `);
  counter++;
  if (counter === array.length) {
    $sliderTop.append(featuresArray.join(''));
    slickSlider();
  }
});
