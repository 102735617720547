import inclusions from '~/js/sections/root/includes/inclusion-slides';
const inclusionSlides = [];
let counter = 0;
const $sliderTop = $('#qualityInclusionsSlider .slide-wrap');

function preloadSlide($slider, currentSlide, amount) {
  const $imagePrevObj = $slider.find(`div[data-slick-index="${currentSlide - amount}"]`).find('.slide-image');
  const $imageNextObj = $slider.find(`div[data-slick-index="${currentSlide + amount}"]`).find('.slide-image');
  const imagePrevSrc = $imagePrevObj.data('src');
  const imageNextSrc = $imageNextObj.data('src');

  if (imagePrevSrc !== $imagePrevObj.attr('src')) {
    $imagePrevObj.attr('src', imagePrevSrc);
  }
  if (imageNextSrc !== $imageNextObj.attr('src')) {
    $imageNextObj.attr('src', imageNextSrc);
  }
}

const slickSlider = () => {

  $sliderTop.slick({
    centerMode: true,
    cssEase: 'ease',
    fade: false,
    infinite: true,
    pauseOnFocus: true,
    pauseOnHover: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 6000,
    variableWidth: true,
    slidesToScroll: 1,
    prevArrow: $('#qualityInclusionsSlider .js-prev-slide-in-slider-top'),
    nextArrow: $('#qualityInclusionsSlider .js-next-slide-in-slider-top'),
    responsive: [{
      breakpoint: 767,
      settings: {
        centerMode: false
      }
    }]
  });

  $sliderTop.on('beforeChange', (event, slick, currentSlide) => {
    preloadSlide($sliderTop, currentSlide, 2);
    preloadSlide($sliderTop, currentSlide, 3);
    preloadSlide($sliderTop, currentSlide, 16);
  });

  $sliderTop.on('afterChange', (event, slick, currentSlide) => {
    preloadSlide($sliderTop, currentSlide, 1);
    preloadSlide($sliderTop, currentSlide, 2);
    preloadSlide($sliderTop, currentSlide, 16);
  });
};

inclusions.inclusion.forEach((inclusion, index, array) => {
  const cond = (inclusion.included !== '');
  const optional = (inclusion.included.toLowerCase().trim() === 'optional');
  const logo = (inclusion.logo !== '');
  const idText = inclusion.alt.replace(/\s+/g, '-').toLowerCase();

  inclusionSlides.push(`
        <div class='slider-item ${idText}' id='${idText}'>
            <a href='${inclusion.link}'>
                <div class='slider-item-inner'>
                    ${logo ? `<img src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' data-src='${inclusion.logo}' class='logo lazyload'>` : ''}
                    <img class="slide-image" src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' data-src='${inclusion.img}' />
                </div>
                <div class='text-box'>
                    ${cond && optional ? `<div class='included optional'>${inclusion.included}</div>` : ''}
                    ${cond && !optional ? `<div class='included'>${inclusion.included}</div>` : ''}
                    <div class='text-wrap'>
                      <h3>${inclusion.top}</h3>
                      <p>${inclusion.bottom}</p>
                    </div>
                </div>
            </a>
        </div>
        `);
  counter++;
  if (counter === array.length) {
    $sliderTop.append(inclusionSlides.join(''));
    slickSlider();
  }
});
