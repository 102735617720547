const newFeatures = {
  feature: [{
    header: 'Single-Use Plastic Straws',
    subHeader: 'sandals & Beaches eliminates',
    paragraph: 'Sandals Resorts is committed to moving beyond single-use plastic. Through a new partnership with Oceanic Global, a non-profit focused on providing solutions to issues impacting our oceans, every Beaches Resort will eliminate single-use plastic straws and stirrers used across the resorts. Eco-friendly paper straws will be available upon request.',
    tag: 'Starting November 1, 2018',
    link: '<a href=\'/about/eco-friendly/\'>Learn More <i class=\'icon-link-arrow-beaches\'></i></a>',
    alt: 'Plastic Straw',
    img: '//cdn.sandals.com/beaches/v12/images/home/whats-new/plastic-straws.jpg'
  },
  {
    header: 'Waterpark Food Trucks',
    subHeader: 'All-New!',
    paragraph: 'Now you can enjoy creative street food concepts in between laps on the lazy river or waterslides with Beaches new waterpark food trucks. From the Mac Daddy of cheese food trucks to the chilliest ice cream food truck.',
    tag: 'At Beaches Turks &amp; Caicos',
    link: '',
    alt: 'Food Trucks',
    img: '//cdn.sandals.com/beaches/v12/images/home/whats-new/food-trucks.jpg'
  },
  {
    header: 'Kicks Off Soccer Clinics at Beaches',
    subHeader: 'Partnership with the real madrid foundation',
    paragraph: 'Sandals Resorts International (SRI) proudly announced their new collaboration with The Real Madrid Foundation to implement the first-ever series of soccer clinics at Beaches Resorts in 2019. Each training session will feature immersive instruction and sport values sessions by the Real Madrid Foundation coaches.',
    tag: 'Coming Soon!',
    link: '<a href=\'/liv-plus/real-madrid-clinic/\'>Learn More <i class=\'icon-link-arrow-beaches\'></i></a>',
    alt: 'Real Madrid Soccer Clinic',
    img: '//cdn.sandals.com/beaches/v12/images/home/whats-new/soccer-clinic.jpg'
  }]
};

module.exports = newFeatures;
