const inclusions = {
  inclusion: [
    {
      top: 'Most Awarded Family All-Inclusive Resorts Ever',
      bottom: 'Our resorts have garnered a worldwide reputation as being among the best all-inclusive resorts in the Caribbean.',
      included: '',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/award-group.png',
      alt: 'Awarded Resort',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/family-dock.jpg',
      link: '/awards/'
    },
    {
      top: 'Luxurious Rooms &amp; Suites',
      bottom: 'Whether it\'s a room for two or a more expansive enclave for your extended clan, Beaches has a room or suite that\'s just the right fit.',
      included: '',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/together-nest.svg',
      alt: 'Luxury Rooms',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/luxury-rooms.jpg',
      link: '/all-inclusive/accommodations/'
    },
    {
      top: 'Personal Butler Service',
      bottom: 'Our unprecedented Butler Service provides an unimaginably supreme standard of service and luxury, where your every need is anticipated.',
      included: 'Included in Top-Tier Suites',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/butler-icon.svg',
      alt: 'Butler Service',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/family-with-butler.jpg',
      link: '/all-inclusive/butler-service/'
    },
    {
      top: '#1 All-Inclusive Dining Experience',
      bottom: 'Our award-winning culinary team of chefs bring up to 21 unique international culinary concepts to life.',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/global-gourmet.svg',
      alt: 'Global Gourmet',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/family-dinner.jpg',
      link: '/all-inclusive/dining/'
    },
    {
      top: 'Unlimited Premium Liquors',
      bottom: 'With 24–hour access to unlimited premium liquors, our bars provide the perfect inspiration for high–spirited gatherings.',
      included: 'Included',
      logo: '',
      alt: 'Premium Spirits',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/premium-liquors.jpg',
      link: '/all-inclusive/premium-drinks/'
    },
    {
      top: 'Scuba Diving',
      bottom: 'Only Beaches includes the Caribbean’s most comprehensive scuba programme with the best in equipment, staff and unbelievable dive sites.',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/scuba-top5.svg',
      alt: 'Scuba Divers',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/scuba-divers.jpg',
      link: '/scuba/'
    },
    {
      top: 'Pirate Island Waterparks',
      bottom: 'Get ready to have an absolute blast at Pirates Island, the most totally thrilling waterparks in the Caribbean...even grown ups have the time of their lives.',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/pirate-logo.png',
      alt: 'Pirates Island Waterpark',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/pirate-island-waterpark.jpg',
      link: '/activities/waterparks/'
    },
    {
      top: 'Kids Programmes',
      bottom: 'At Beaches, we give kids extra attention with tailor-made fun just for them, offering age-appropriate activities for kids of all ages. They\'re always supervised by INA certified nannnies.',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/take-care-kids.png',
      alt: 'Day Care',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/kids-camp.jpg',
      link: '/scuba/kids-programs/'
    },
    {
      top: 'Sesame Street<sup>&reg;</sup>',
      bottom: 'Our exclusive partnership with Sesame Street<sup>&reg;</sup> makes Beaches the only Caribbean resorts where kids can play every day with the lovable, colourful and friendly cast of characters.',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/sesame-street-badge.png',
      alt: 'Sesame Street',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/sesame-street.jpg',
      link: '/activities/sesame-street/'
    },
    {
      top: 'Teen Activities',
      bottom: 'Sail, windsurf and snorkel. Play foosball and hoops at Trench Town. Join a tournament at the Gaming Lounge. Dance the night away at Club Liquid...at Beaches everything’s included.',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/teen-party-badge.svg',
      alt: 'Teen Activities',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/teens-party.jpg',
      link: '/holiday/teens/'
    },
    {
      top: 'Water Sports',
      bottom: 'Sail away and indulge in a vast array of all-inclusive water sports. With our top-of-the-line equipment, professional staff and unparalleled tropical locations.',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/aqua-center-beaches.svg',
      alt: 'Aqua Centre',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/aqua-center.jpg',
      link: '/activities/watersports/'
    },
    {
      top: 'Land Sports',
      bottom: 'From the sands to the courts, Beaches all-inclusive resorts feature unlimited land sports, so there are no limits to what you can play, when you can play.',
      included: 'Included',
      logo: '',
      alt: 'Land Sports',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/land-sports.jpg',
      link: '/activities/land-sports/'
    },
    {
      top: 'Golf at Beaches',
      bottom: 'A Beaches golf holiday in the Caribbean includes more than any other destiantion for family resort holiday.',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/sandals-golf-country-club.svg',
      alt: 'Golf Course',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/sandals-golf-country-club.jpg',
      link: '/activities/golf/'
    },
    {
      top: 'Day &amp; Night Entertainment',
      bottom: 'At Beaches there are endless ways to have fun day and night. From chic gatherings at the beach to exciting shows, parties & events, and much more...',
      included: 'Included',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/day-night-entertainment-badge.svg',
      alt: 'Day Night Entertainment',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/beaches-nightlife.jpg',
      link: '/activities/entertainment/'
    },
    {
      top: 'Weddings &amp; Honeymoons',
      bottom: 'At Beaches, we understand that your wedding should be as unique as your love story. We’ve replaced wedding packages with wedding planners and your options are endless, so you can mix and match to create your very own vision.',
      included: '',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/weddings-free-badge.svg',
      alt: 'Weddings and Honeymoons',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/weddings-honeymoons.jpg',
      link: '/weddingmoons/'
    },
    {
      top: 'Award-Winning Red Lane<sup>&reg;</sup> Spa',
      bottom: 'Red Lane<sup>®</sup> Spa reinterpret classic European rituals with distinctive Caribbean flair. Experience serene indoor sanctuaries or exotic outdoor settings.',
      included: 'Optional',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/redlane-spa-logo.svg',
      alt: 'Redlane Spa',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/redlane-spa.jpg',
      link: '/redlane-spa/'
    },
    {
      top: 'Caribbean Adventure Tours',
      bottom: 'Experience all the thrills, natural beauty and vibrant culture that make the Caribbean such a sought-after destination.',
      included: 'Optional',
      logo: '//cdn.sandals.com/beaches/v12/images/home/included-slider/island-routes-logo.svg',
      alt: 'Adventure Tours',
      img: '//cdn.sandals.com/beaches/v12/images/home/included-slider/adventure-tours.jpg',
      link: '/tours/'
    }
  ]
};

module.exports = inclusions;
