const resorts = {
  resort: [{
    name: 'Turks &amp; Caicos',
    city: 'Providenciales',
    country: 'Turks &amp; Caicos',
    rstCode: 'BTC',
    link: '/resorts/turks-caicos/',
    featured: true,
    new: false,
    caption: 'Last of the True Exotics in the First for Families',
    img: '//cdn.sandals.com/beaches/v12/images/home/resorts/beaches-turks.jpg'
  },
  {
    name: 'Ocho Rios',
    city: 'Ocho Rios',
    country: 'Jamaica',
    rstCode: 'BBO',
    link: '/resorts/ocho-rios/',
    featured: false,
    new: false,
    caption: 'Voted Top 25 Caribbean Hotels for Families by TripAdvisor<sup>®</sup> Reviewers',
    img: '//cdn.sandals.com/beaches/v12/images/home/resorts/beaches-ocho-rios.jpg'
  },
  {
    name: 'Negril',
    city: 'Negril',
    country: 'Jamaica',
    rstCode: 'BNG',
    link: '/resorts/negril/',
    featured: false,
    new: false,
    caption: 'Two Distinct Resort Experiences Surrounded by Natural Jamaica',
    img: '//cdn.sandals.com/beaches/v12/images/home/resorts/beaches-negril.jpg'
  }]
};

module.exports = resorts;
